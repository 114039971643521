import { NonRetriableError } from "inngest";
import type { ParseError } from "psl";
import { get } from "psl";

export const processStream = async (res: Response) => {
  const reader = res.body?.getReader();
  const decoder = new TextDecoder("utf-8");
  let result = "";
  let response = await reader?.read();
  while (response && !response.done) {
    const value = response.value;
    result += decoder.decode(value);
    response = await reader?.read();
  }
  console.log("Stream complete", result);
};

export const mapToTriggerFormat = <TArr extends string[] = string[]>(input?: TArr) =>
  (input?.length ?? 0) > 0 ? input?.reduce((acc, cur) => `${acc}, ${cur}`) : "";

// Create an API error that can be thrown with a message and a status code

export class APIError extends Error {
  constructor(
    message: string,
    public status: number,
  ) {
    super(message);
  }
}

export class PaymentRequiredError extends NonRetriableError {
  constructor(message: string) {
    super(message, {
      cause: "PaymentRequiredError - Not enough credits",
    });
    this.name = "PaymentRequiredError";
  }
}

export class PaymentError extends NonRetriableError {
  constructor(message: string) {
    super(message, {
      cause: "PaymentError - Error with Stripe",
    });
    this.name = "PaymentError";
  }
}

export const validateIndustry = (industry: string, triggerIndustries: string[], triggerIndustryExclude: string[]) => {
  if (triggerIndustries.length > 0) {
    if (!triggerIndustries.includes(industry)) {
      return false;
    }
  }

  if (triggerIndustryExclude.length > 0) {
    if (triggerIndustryExclude.includes(industry)) {
      return false;
    }
  }

  return true;
};

export function getDomain(url?: string) {
  try {
    if (!url) {
      return null;
    }
    // make sure theres atleast 3 parts to the url if not assume its a domain and return it
    const parts = url.split(".");
    if ((parts.length ?? 0) < 3 && (parts.length ?? 0) > 1 && !url.startsWith("http") && !url.startsWith("https")) {
      return url;
    }

    let newUrl = url;
    if (url.startsWith("http") || url.startsWith("https")) {
      newUrl = new URL(url).hostname;
    }

    const result = get(newUrl);

    console.log(`Domain: ${result}`);

    return result;
  } catch (e) {
    const err = e as ParseError;
    console.log(err);
    console.warn(`Invalid URL: ${url}`);
    return null;
  }
}

export const removeProtocol = (url: string) => {
  const removedProtocol = url.replace(/^https?:\/\//, "");
  const removedSubdomain = removedProtocol.replace(/www\./, "");
  // remove trailing slash
  return removedSubdomain.replace(/\/$/, "");
};

export const createHaskeyFromStringArray = (arr: string[]) => {
  return arr.reduce((acc, cur) => {
    // take the vanity name after /in/ and use that as the key
    const key = cur.split("/in/")[1];
    return `${acc}_${key}`;
  }, "");
};
