export const BATCH_SIZE = 10;

export const LIMIT_MAP = {
  JUNIOR: 50,
  SENIOR: 150,
  ENTERPRISE: 300,
  TEST: 20,
  FREEMIUM: 20,
};

export const MAX_PROSPECTS_PER_COMPANY = 3;
