import { useUser } from "@clerk/nextjs";
import dayjs from "dayjs";
import { SubscriptionService } from "~/services/subscriptions";
import { api } from "~/trpc/react";

export const useGetUserPlan = () => {
  const { isSignedIn, user } = useUser();

  const {
    data: userPlan,
    isLoading,
    refetch,
  } = api.settings.getUserPlan.useQuery(undefined, {
    refetchOnWindowFocus: false,
    enabled: isSignedIn && !!user?.id,
  });

  const freeTrialEnd = dayjs(userPlan?.date_joined).add(SubscriptionService.getFreeTrialDays(), "day");
  const timeRemaining = freeTrialEnd.diff(dayjs(), "day");

  return {
    userPlan,
    isLoading,
    refetch,
    isFreeTrial: userPlan?.is_free_trial,
    user,
    freeTrialInfo: {
      freeTrialEnd,
      timeRemaining,
    },
  };
};
