import type { Message } from "ai";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import type { PersonResponse } from "peopledatalabs";
import type { DeepPartial } from "react-hook-form";
import { BATCH_SIZE } from "./inngest/config";
import type { PersonList } from "./types/Leads";
import type { IntegrationContact } from "./types/integrations";
import type { MoverModel } from "./types/triggers";
import type { LinkedInResult } from "./types/linkedin";
import type { OnboardingStep } from "@prisma/client";
import { slugify } from "inngest";

export const isArray = (value: unknown): value is [] => Array.isArray(value);

export type TRPCResponse<TShape> = {
  result: {
    data: {
      json: TShape;
    };
  };
};

export const wrapData = <TShape>(json: TShape): TRPCResponse<TShape> => {
  return {
    result: {
      data: {
        json,
      },
    },
  };
};

export const removeHTMLTags = (html: string) => {
  return html.replace(/<[^>]*>?/gm, "");
};

export const generateUniqueFileName = (fileName: string, ext: "csv") => {
  const datestamp = new Date().toISOString().slice(0, 10);
  return `${fileName}-${datestamp}.${ext}`;
};

export const truncateText = (text: string, length: number) => {
  if (text.length > length) {
    return `${text.slice(0, length)}...`;
  }

  return text;
};

export const createMessage = (message: string, role: "user" | "assistant"): Message => {
  return {
    createdAt: new Date(),
    id: "1",
    content: message,
    role,
  };
};

export const formatBlockOfTextToParagraphs = (text: string) => {
  // take a chunk of text and create new lines after every full stop or 100 characters
  const textArray = text.split(".");
  const paragraphs = [];
  let paragraph = "";
  for (const chunk of textArray) {
    if (paragraph.length < 100) {
      paragraph += chunk + ".";
    } else {
      paragraphs.push(paragraph);
      paragraph = "";
    }
  }
  return paragraphs.join("\n");
};

// create a function that will conver Time from now to an actual date
// ex: 2 days ago => 2021-07-07
// ex: 2 weeks ago => 2021-06-30
// ex: 2 months ago => 2021-05-09
// ex: 2 years ago => 2019-07-09
export const convertTimeAgoToDate = (timeAgo: string) => {
  const timeAgoArray = timeAgo.split(" ");
  if (timeAgoArray.length !== 2) {
    return null;
  }
  const timeAgoNumber = Number.parseInt(timeAgoArray[0]!);
  const timeAgoUnit = timeAgoArray[1];
  const date = new Date();
  if (timeAgoUnit === "days") {
    date.setDate(date.getDate() - timeAgoNumber);
  } else if (timeAgoUnit === "weeks") {
    date.setDate(date.getDate() - timeAgoNumber * 7);
  } else if (timeAgoUnit === "months") {
    date.setMonth(date.getMonth() - timeAgoNumber);
  } else if (timeAgoUnit === "years") {
    date.setFullYear(date.getFullYear() - timeAgoNumber);
  }
  return date.toISOString().slice(0, 10);
};

export const splitIntoChunks = <T>(items: T[], chunkSize: number = BATCH_SIZE) => {
  const chunks = [];
  let i = 0;
  while (i < items.length) {
    const chunk = items.slice(i, (i += chunkSize));
    chunks.push(chunk);
  }

  return chunks;
};

export function createReadableError(error: string) {
  try {
    const parsedError = JSON.parse(error) as {
      path: string[];
      message: string;
      received: string;
      options: string[];
    }[];
    const errorDetails = parsedError[0];
    const path = errorDetails?.path?.join(" > ");

    return `Error in ${path}: ${errorDetails?.message}. Received '${errorDetails?.received}', but expected one of the following: ${errorDetails?.options.join(", ")}.`;
  } catch (e) {
    return `Unable to parse error message: ${error}`;
  }
}

export const getSafePreviousCompanyName = (person: PersonResponse) => {
  // if the person has a previous company there should be 2 or more elements in the array
  // always take the 2nd element.. if it doesn't exist, return an empty string
  return person.experience?.length! >= 2 ? person.experience![1]?.company?.name : "";
};

const generateNameFromFullName = (fullName: string) => {
  const firstName = fullName?.split(" ")[0];
  const lastName = fullName?.split(" ")[1];
  return {
    firstName: capitalize(firstName),
    lastName: capitalize(lastName),
    fullName: `${capitalize(firstName)} ${capitalize(lastName)}`,
  };
};

export const mapPDLPersonToHiringPerson = (person: PersonResponse): MoverModel => {
  const { firstName, lastName, fullName } = generateNameFromFullName(person.full_name!);
  return {
    hiring_prospect: {
      first_name: firstName,
      last_name: lastName,
      full_name: fullName,
      linkedin_url: person.linkedin_url,
      location_name: person.location_name,
      job_title: capitalize(person.job_title ?? ""),
      previous_company_name: getSafePreviousCompanyName(person),
      job_start_date: person.job_start_date ?? "",
      birth_date: person.birth_date ?? "",
      job_summary: person.job_summary ?? "",
      job_title_levels: person.job_title_levels ?? [],
      birth_year: person.birth_year?.toString() ?? "",
      facebook_id: person.facebook_id ?? "",
      facebook_url: person.facebook_url ?? "",
      facebook_username: person.facebook_username ?? "",
      github_url: person.github_url ?? "",
      github_username: person.github_username ?? "",
      linkedin_id: person.linkedin_id ?? "",
      linkedin_username: person.linkedin_username ?? "",
      location_country: person.location_country ?? "",
      location_geo: person.location_geo ?? "",
      location_region: person.location_region ?? "",
      mobile_phone: person.mobile_phone ?? "",
      recommended_personal_email: person.recommended_personal_email ?? "",
      twitter_url: person.twitter_url ?? "",
      twitter_username: person.twitter_username ?? "",
      work_email: person.work_email ?? "",
      job_company_name: person.job_company_name ?? "",
      job_company_website: person.job_company_website ?? "",
      job_title_role: person.job_title_role ?? "",
      job_title_sub_role: person.job_title_sub_role ?? "",
    },
    hiring_company: {
      company_logo: `https://logo.clearbit.com/${person.job_company_website}`,
      country: person.job_company_location_country ?? "",
      description: "",
      total_funding: person.job_company_total_funding_raised ?? 0,
      revenue: person.job_company_inferred_revenue ?? "",
      industry: person.industry ?? person.job_company_industry ?? "",
      city: person.job_company_location_name ?? "",
      domain: person.job_company_website!,
      name: person.job_company_name!,
      size: person.job_company_size ?? "",
      location: person.job_company_location_name ?? "",
      employees_count: person.job_company_size ?? "",
      linkedin_url: person.job_company_linkedin_url ?? "",
      annual_growth_rate: person.job_company_12mo_employee_growth_rate ?? 0,
    },
    hiring_email_template: {
      body: "",
    },
  };
};

export const replacePlaceholersInIntegrationContactTemplate = (
  template: string,
  lead: DeepPartial<IntegrationContact>,
) => {
  const newString = template;
  const replaceMap = {
    "[prospect.location_name]": lead?.location_name,
    "[prospect.first_name]": lead?.first_name,
    "[prospect.last_name]": lead?.last_name,
    "[prospect.full_name]": `${lead?.first_name} ${lead?.last_name}`,
    "[prospect.linkedin_url]": lead?.linkedin_url,
    "[prospect.job_title]": lead?.job_title,
    "[prospect.email]": lead?.email,
    "[prospect.recommended_personal_email]": lead?.recommended_personal_email,
    "[prospect.work_email]": lead?.work_email,
    "[prospect.mobile_phone]": lead?.mobile_phone,
    "[prospect.birth_date]": lead?.birth_date,
    "[prospect.job_start_date]": lead?.job_start_date,
    "[prospect.job_summary]": lead?.job_summary,
    "[prospect.location]": lead?.location_name,
    "[prospect.github_url]": lead?.github_url,
    "[prospect.github_username]": lead?.github_username,
    "[prospect.facebook_url]": lead?.facebook_url,
    "[prospect.facebook_username]": lead?.facebook_username,
    "[prospect.facebook_id]": lead?.facebook_id,
    "[prospect.linkedin_id]": lead?.linkedin_id,
    "[prospect.linkedin_username]": lead?.linkedin_username,
    "[prospect.twitter_url]": lead?.twitter_url,
    "[prospect.twitter_username]": lead?.twitter_username,
    // "[hiring_job.title]": lead.company?.jobtitle,
    // "[hiring_job.description]": removeHTMLTags(
    //   lead.company?.jobdescription ?? ""
    // ),
    // "[hiring_job.location]": lead.hiring_job?.location,
    // "[hiring_job.apply_url]": lead.hiring_job?.apply_url,
    "[company.size]": lead.company?.size,
    "[company.name]": lead.company?.name,
    "[company.industry]": lead.company?.industry,
    "[company.location]": lead.company?.location,
    "[company.revenue]": lead.company?.revenue ?? "Revenue - Unknown",
    "[company.events]": lead.company?.events?.map((e) => e?.categories?.join(", "))?.join(", ") ?? "Events - Unknown",
    "[company.employees_count]": lead.company?.employees_count ?? "Employees - Unknown",
    "[company.monthly_visitors]": lead.company?.monthly_visitors ?? "Monthly Visitors - Unknown",
    "[company.technologies]": lead.company?.technologies?.join(", ") ?? "Technologies - Unknown",
    "[company.total_funding]": String(lead.company?.total_funding ?? "Total Funding - Unknown"),
    "[company.domain]": String(lead.company?.domain),
  };

  // Replace strings
  let finalString = replacePlaceholders(newString, replaceMap);

  // Replace cell variables
  finalString = replacePlaceholdersForCells(finalString, lead);

  return finalString;
};
export const replacePlaceholersInTemplate = (
  template: string,
  lead: DeepPartial<PersonList> & DeepPartial<LinkedInResult>,
) => {
  const newString = template;
  const replaceMap = {
    "[prospect.location_name]": lead.prospect?.location_name,
    "[prospect.first_name]": lead.prospect?.first_name,
    "[prospect.last_name]": lead.prospect?.last_name,
    "[prospect.full_name]": lead.prospect?.full_name,
    "[prospect.linkedin_url]": lead.prospect?.linkedin_url,
    "[prospect.job_title]": lead.prospect?.job_title,
    "[prospect.enriched_emails[0].email]": lead.prospect?.enriched_emails?.[0]?.email,
    "[prospect.enriched_profile.previous_company_name]": lead.prospect?.enriched_profile?.previous_company_name,
    "[prospect.enriched_profile.recommended_personal_email]":
      lead.prospect?.enriched_profile?.recommended_personal_email,
    "[prospect.enriched_profile.work_email]": lead.prospect?.enriched_profile?.work_email,
    "[prospect.enriched_profile.mobile_phone]": lead.prospect?.enriched_profile?.mobile_phone,
    "[prospect.enriched_profile.birth_date]": lead.prospect?.enriched_profile?.birth_date,
    "[prospect.enriched_profile.job_start_date]": lead.prospect?.enriched_profile?.job_start_date,
    "[prospect.enriched_profile.job_summary]": lead.prospect?.enriched_profile?.job_summary,
    "[prospect.location]": lead.prospect?.location_name,
    "[prospect.enriched_profile.github_url]": lead.prospect?.enriched_profile?.github_url,
    "[prospect.enriched_profile.github_username]": lead.prospect?.enriched_profile?.github_username,
    "[prospect.enriched_profile.facebook_url]": lead.prospect?.enriched_profile?.facebook_url,
    "[prospect.enriched_profile.facebook_username]": lead.prospect?.enriched_profile?.facebook_username,
    "[prospect.enriched_profile.facebook_id]": lead.prospect?.enriched_profile?.facebook_id,
    "[prospect.enriched_profile.linkedin_id]": lead.prospect?.enriched_profile?.linkedin_id,
    "[prospect.enriched_profile.linkedin_username]": lead.prospect?.enriched_profile?.linkedin_username,
    "[prospect.enriched_profile.twitter_url]": lead.prospect?.enriched_profile?.twitter_url,
    "[prospect.enriched_profile.twitter_username]": lead.prospect?.enriched_profile?.twitter_username,
    "[prospect.enriched_profile.birth_year]": lead.prospect?.enriched_profile?.birth_year,
    "[prospect.prospect_organisation_tag]": lead.prospect?.prospect_organisation_tag?.map((t) => t?.title).join(", "),
    "[linkedin_engagement.comments]":
      lead.linkedin_engagement?.filter((e) => e?.type === "COMMENT").length.toString() + " comments",
    "[linkedin_engagement.likes]":
      lead.linkedin_engagement?.filter((e) => e?.type === "LIKE").length.toString() + " likes",
    "[linkedin_engagement.post]":
      lead?.linkedin_engagement?.find((e) => e?.author_id === lead?.prospect_id)?.linkedin_post?.text ?? "",
    "[hiring_job.title]": lead.hiring_job?.title,
    "[hiring_job.description]": removeHTMLTags(lead.hiring_job?.description ?? ""),
    "[job.location]": lead.job?.location,
    "[job.apply_url]": lead.job?.apply_url,
    "[hiring_job.location]": lead.hiring_job?.location,
    "[hiring_job.apply_url]": lead.hiring_job?.apply_url,
    "[company.size]": lead.company?.size,
    "[company.name]": lead.company?.name,
    "[company.industry]": lead.company?.industry,
    "[company.location]": lead.company?.location,
    "[hiring_company.size]": lead.hiring_company?.size,
    "[hiring_company.name]": lead.hiring_company?.name,
    "[hiring_company.industry]": lead.hiring_company?.industry,
    "[hiring_company.location]": lead.hiring_company?.location,
    "[company.revenue]": lead.company?.revenue ?? "Revenue - Unknown",
    "[hiring_company.revenue]": lead.hiring_company?.revenue ?? "Revenue - Unknown",
    "[company.events]": lead.company?.events?.map((e) => e?.categories?.join(", "))?.join(", ") ?? "Events - Unknown",
    "[hiring_company.events]":
      lead.hiring_company?.events?.map((e) => e?.categories?.join(", "))?.join(", ") ?? "Events - Unknown",
    "[company.employees_count]": lead.company?.employees_count ?? "Employees - Unknown",
    "[company.monthly_visitors]": lead.company?.monthly_visitors ?? "Monthly Visitors - Unknown",
    "[hiring_company.employees_count]": lead.hiring_company?.employees_count ?? "Employees - Unknown",
    "[hiring_company.monthly_visitors]": lead.hiring_company?.monthly_visitors ?? "Monthly Visitors - Unknown",

    "[company.technologies]": lead.company?.technologies?.join(", ") ?? "Technologies - Unknown",
    "[hiring_company.technologies]": lead.hiring_company?.technologies?.join(", ") ?? "Technologies - Unknown",
    "[company.total_funding]": String(lead.company?.total_funding ?? "Total Funding - Unknown"),
    "[hiring_company.total_funding]": String(lead.hiring_company?.total_funding ?? "Total Funding - Unknown"),
    "[company.domain]": String(lead.company?.domain),
    "[hiring_company.domain]": String(lead.hiring_company?.domain),
  };

  // Replace strings
  let finalString = replacePlaceholders(newString, replaceMap);

  // Replace cell variables
  finalString = replacePlaceholdersForCells(finalString, lead);

  return finalString;
};

function replacePlaceholders(template: string, replaceMap: Record<string, string | undefined | null>) {
  const regex = /\[([a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+|\[[0-9]+\])+)\]/g;

  return template.replace(regex, (match) => {
    return replaceMap[match] ?? match; // if no match, return original string
  });
}

function replacePlaceholdersForCells(template: string, lead: DeepPartial<PersonList>) {
  const regex = /\[trigiq_cells@(\d+)\]/g;
  return template.replace(regex, (match, id) => {
    const valueFound = lead.trigiq_cells?.find((d) => `${d?.trigiq_column_id}` === `${id}`);
    return valueFound ? valueFound.value! : match;
  });
}

export const addOneMonthStartOfDay = () => dayjs().add(1, "month").startOf("day").toDate();

export function findMostRecent(...dates: Date[]) {
  let mostRecent = dayjs(dates[0]);

  for (let i = 1; i < dates.length; i++) {
    const current = dayjs(dates[i]);
    if (current.isAfter(mostRecent)) {
      mostRecent = current;
    }
  }

  return mostRecent.toDate();
}

export const getKnockAppDetails = (tenant: string) => {
  return {
    objectId: slugify(tenant),
    collection: "notifications",
  };
};

export const mapStepToPath = (step?: OnboardingStep) => {
  switch (step) {
    case "CONNECT_SLACK":
      return "/onboarding/slack";
    case "ABOUT_YOU":
      return "/onboarding/about-you";
    case "CREATE_ORGANISATION":
      return "/onboarding/company";
    case "CONNECT_INTEGRATIONS":
      return "/onboarding/integrations";
    case "FINISHED":
      return "/onboarding/finished";
    default:
      return "/onboarding/about-you";
  }
};
