import type { User, WebhookEvent, clerkClient } from "@clerk/nextjs/server";
import { CreditAction, Prisma, SubscriptionInterval, UserSubscription } from "@prisma/client";
import type { account_user, subscription_item } from "@prisma/client";
import type { Inngest } from "inngest";
import type { Stripe } from "stripe";
import type winston from "winston";
import { shortId } from "~/server/utils";
import { addOneMonthStartOfDay } from "~/utils";
import { PostHogEvents } from "./third-party/posthog";
import type { PosthogService } from "./third-party/posthog";
import type { SlackService } from "./third-party/slack";
import { PaymentRequiredError } from "./utilts";
import dayjs from "dayjs";
import type { IntegrationEvents } from "~/inngest/schemas/integrations";
import type { PrismaClient } from "@trigify/prisma";

const matrix = {
  metered: {
    email: {
      [UserSubscription.FREEMIUM]: 0,
      [UserSubscription.SOCIAL_SIGNAL]: 500,
      [UserSubscription.JUNIOR]: 500,
      [UserSubscription.GROWTH]: 2000,
      [UserSubscription.JUNIOR_PLUS]: 2000,
      [UserSubscription.SENIOR]: 2000,
      [UserSubscription.SCALE]: 5000,
      [UserSubscription.SENIOR_PLUS]: 5000,
      [UserSubscription.CUSTOM]: 10_000,
      [UserSubscription.ENTERPRISE]: 10_000,
    },
    phone: {
      [UserSubscription.FREEMIUM]: 0,
      [UserSubscription.SOCIAL_SIGNAL]: 100,
      [UserSubscription.JUNIOR]: 100,
      [UserSubscription.JUNIOR_PLUS]: 500,
      [UserSubscription.GROWTH]: 500,
      [UserSubscription.SENIOR]: 500,
      [UserSubscription.SCALE]: 1000,
      [UserSubscription.SENIOR_PLUS]: 1000,
      [UserSubscription.CUSTOM]: 2000,
      [UserSubscription.ENTERPRISE]: 2000,
    },
  },
  static: {
    people: {
      [UserSubscription.FREEMIUM]: 0.3,
      [UserSubscription.JUNIOR]: 0.3,
      [UserSubscription.JUNIOR_PLUS]: 0.3,
      [UserSubscription.SENIOR]: 0.3,
      [UserSubscription.SENIOR_PLUS]: 0.3,
      [UserSubscription.ENTERPRISE]: 0.3,
      [UserSubscription.SOCIAL_SIGNAL]: 0.3,
      [UserSubscription.GROWTH]: 0.3,
      [UserSubscription.SCALE]: 0.3,
      [UserSubscription.CUSTOM]: 0.3,
    },
    company: {
      [UserSubscription.FREEMIUM]: 0.3,
      [UserSubscription.JUNIOR]: 0.3,
      [UserSubscription.JUNIOR_PLUS]: 0.3,
      [UserSubscription.SENIOR]: 0.3,
      [UserSubscription.SENIOR_PLUS]: 0.3,
      [UserSubscription.ENTERPRISE]: 0.3,
      [UserSubscription.SOCIAL_SIGNAL]: 0.3,
      [UserSubscription.GROWTH]: 0.3,
      [UserSubscription.SCALE]: 0.3,
      [UserSubscription.CUSTOM]: 0.3,
    },
    coresignal: {
      [UserSubscription.FREEMIUM]: 2,
      [UserSubscription.JUNIOR]: 2,
      [UserSubscription.JUNIOR_PLUS]: 2,
      [UserSubscription.SENIOR]: 2,
      [UserSubscription.SENIOR_PLUS]: 2,
      [UserSubscription.ENTERPRISE]: 2,
      [UserSubscription.SOCIAL_SIGNAL]: 2,
      [UserSubscription.GROWTH]: 2,
      [UserSubscription.SCALE]: 2,
      [UserSubscription.CUSTOM]: 2,
    },
  },
  shared: {
    email: {
      [UserSubscription.FREEMIUM]: 1,
      [UserSubscription.JUNIOR]: 1,
      [UserSubscription.JUNIOR_PLUS]: 1,
      [UserSubscription.SENIOR]: 1,
      [UserSubscription.SENIOR_PLUS]: 1,
      [UserSubscription.ENTERPRISE]: 1,
      [UserSubscription.SOCIAL_SIGNAL]: 1,
      [UserSubscription.GROWTH]: 1,
      [UserSubscription.SCALE]: 1,
      [UserSubscription.CUSTOM]: 1,
    },
    phone: {
      [UserSubscription.FREEMIUM]: 6,
      [UserSubscription.JUNIOR]: 6,
      [UserSubscription.JUNIOR_PLUS]: 6,
      [UserSubscription.SENIOR]: 4.5,
      [UserSubscription.SENIOR_PLUS]: 4.5,
      [UserSubscription.ENTERPRISE]: 2.5,
      [UserSubscription.SOCIAL_SIGNAL]: 0.3,
      [UserSubscription.GROWTH]: 0.3,
      [UserSubscription.SCALE]: 0.3,
      [UserSubscription.CUSTOM]: 0.3,
    },
    aiScript: {
      [UserSubscription.FREEMIUM]: 1,
      [UserSubscription.JUNIOR]: 1,
      [UserSubscription.JUNIOR_PLUS]: 1,
      [UserSubscription.SENIOR]: 1,
      [UserSubscription.SENIOR_PLUS]: 1,
      [UserSubscription.ENTERPRISE]: 1,
      [UserSubscription.SOCIAL_SIGNAL]: 1,
      [UserSubscription.GROWTH]: 1,
      [UserSubscription.SCALE]: 1,
      [UserSubscription.CUSTOM]: 1,
    },
    personEnrich: {
      [UserSubscription.FREEMIUM]: 2,
      [UserSubscription.JUNIOR]: 2,
      [UserSubscription.JUNIOR_PLUS]: 2,
      [UserSubscription.SENIOR]: 2,
      [UserSubscription.SENIOR_PLUS]: 2,
      [UserSubscription.ENTERPRISE]: 2,
      [UserSubscription.SOCIAL_SIGNAL]: 2,
      [UserSubscription.GROWTH]: 2,
      [UserSubscription.SCALE]: 2,
      [UserSubscription.CUSTOM]: 2,
    },
  },
  hiring: {
    prospecting: {
      [UserSubscription.FREEMIUM]: 2,
      [UserSubscription.JUNIOR]: 2,
      [UserSubscription.JUNIOR_PLUS]: 2,
      [UserSubscription.SENIOR]: 2,
      [UserSubscription.SENIOR_PLUS]: 2,
      [UserSubscription.ENTERPRISE]: 2,
      [UserSubscription.SOCIAL_SIGNAL]: 2,
      [UserSubscription.GROWTH]: 2,
      [UserSubscription.SCALE]: 2,
      [UserSubscription.CUSTOM]: 2,
    },
  },
  mover: {
    prospecting: {
      [UserSubscription.FREEMIUM]: 1,
      [UserSubscription.JUNIOR]: 1,
      [UserSubscription.JUNIOR_PLUS]: 1,
      [UserSubscription.SENIOR]: 1,
      [UserSubscription.SENIOR_PLUS]: 1,
      [UserSubscription.ENTERPRISE]: 1,
      [UserSubscription.SOCIAL_SIGNAL]: 1,
      [UserSubscription.GROWTH]: 1,
      [UserSubscription.SCALE]: 1,
      [UserSubscription.CUSTOM]: 1,
    },
  },
  abm: {
    prospecting: {
      [UserSubscription.FREEMIUM]: 1,
      [UserSubscription.JUNIOR]: 1,
      [UserSubscription.JUNIOR_PLUS]: 1,
      [UserSubscription.SENIOR]: 1,
      [UserSubscription.SENIOR_PLUS]: 1,
      [UserSubscription.ENTERPRISE]: 1,
      [UserSubscription.SOCIAL_SIGNAL]: 1,
      [UserSubscription.GROWTH]: 1,
      [UserSubscription.SCALE]: 1,
      [UserSubscription.CUSTOM]: 1,
    },
    events: {
      [UserSubscription.FREEMIUM]: 1,
      [UserSubscription.JUNIOR]: 1,
      [UserSubscription.JUNIOR_PLUS]: 1,
      [UserSubscription.SENIOR]: 1,
      [UserSubscription.SENIOR_PLUS]: 1,
      [UserSubscription.ENTERPRISE]: 1,
      [UserSubscription.SOCIAL_SIGNAL]: 1,
      [UserSubscription.GROWTH]: 1,
      [UserSubscription.SCALE]: 1,
      [UserSubscription.CUSTOM]: 1,
    },
    companies: {
      [UserSubscription.FREEMIUM]: 0.5,
      [UserSubscription.JUNIOR]: 0.5,
      [UserSubscription.JUNIOR_PLUS]: 0.5,
      [UserSubscription.SENIOR]: 0.5,
      [UserSubscription.SENIOR_PLUS]: 0.5,
      [UserSubscription.ENTERPRISE]: 0.5,
      [UserSubscription.SOCIAL_SIGNAL]: 0.5,
      [UserSubscription.GROWTH]: 0.5,
      [UserSubscription.SCALE]: 0.5,
      [UserSubscription.CUSTOM]: 0.5,
    },
  },
  integration: {
    linkedin: {
      [UserSubscription.FREEMIUM]: 0.2,
      [UserSubscription.JUNIOR]: 0.2,
      [UserSubscription.JUNIOR_PLUS]: 0.2,
      [UserSubscription.SENIOR]: 0.2,
      [UserSubscription.SENIOR_PLUS]: 0.2,
      [UserSubscription.ENTERPRISE]: 0.2,
      [UserSubscription.SOCIAL_SIGNAL]: 0.2,
      [UserSubscription.GROWTH]: 0.2,
      [UserSubscription.SCALE]: 0.2,
      [UserSubscription.CUSTOM]: 0.2,
    },
  },
  linkedinUserNames: {
    [UserSubscription.FREEMIUM]: 1,
    [UserSubscription.SOCIAL_SIGNAL]: 1,
    [UserSubscription.JUNIOR]: 3,
    [UserSubscription.JUNIOR_PLUS]: 3,
    [UserSubscription.GROWTH]: 3,
    [UserSubscription.SENIOR]: 5,
    [UserSubscription.SENIOR_PLUS]: 5,
    [UserSubscription.SCALE]: 5,
    [UserSubscription.CUSTOM]: 5,
    [UserSubscription.ENTERPRISE]: 5,
  },
  smartTags: {
    [UserSubscription.FREEMIUM]: 1,
    [UserSubscription.JUNIOR]: 3,
    [UserSubscription.JUNIOR_PLUS]: 3,
    [UserSubscription.SOCIAL_SIGNAL]: 3,
    [UserSubscription.SENIOR]: 5,
    [UserSubscription.SENIOR_PLUS]: 5,
    [UserSubscription.ENTERPRISE]: 5,
    [UserSubscription.GROWTH]: 5,
    [UserSubscription.SCALE]: 5,
    [UserSubscription.CUSTOM]: 5,
  },
} as const;

// Freemium + Junior
// Hiring 2 - 4
// Market Mover 1 - 3
// ABM 1 - 4

// Senior
// Hiring 2 - 3
// Market Mover 1 - 2
// ABM 1 - 3

// Enterprise
// Hiring 1 - 3
// Market Mover 1 - 2
// ABM 1 - 2

// Freemium | $0 | 250 Actions
// Junior | $185 | 2,000 Actions
// Junior + | $365 | 5,000 Actions
// Senior | $435 | 10,000 Actions
// Senior + | $725 | 20,000 Actions
// Enterprise | $1,160 | 30,000 Actions

const creditMatrix = {
  [UserSubscription.FREEMIUM]: 0,
  [UserSubscription.JUNIOR]: 3000,
  [UserSubscription.SOCIAL_SIGNAL]: 3000,
  [UserSubscription.GROWTH]: 5000,
  [UserSubscription.JUNIOR_PLUS]: 5000,
  [UserSubscription.SENIOR]: 10000,
  [UserSubscription.SCALE]: 10_000,
  [UserSubscription.SENIOR_PLUS]: 20000,
  [UserSubscription.CUSTOM]: 30_000,
  [UserSubscription.ENTERPRISE]: 30000,
  [process.env.NEXT_PUBLIC_STRIPE_FREEMIUM_PRODUCT_ID!]: 0,
  [process.env.NEXT_PUBLIC_STRIPE_JUNIOR_PRODUCT_ID!]: 3000,
  [process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SIGNAL_PRODUCT_ID!]: 3000,
  [process.env.NEXT_PUBLIC_STRIPE_JUNIOR_PLUS_PRODUCT_ID!]: 5000,
  [process.env.NEXT_PUBLIC_STRIPE_GROWTH_PRODUCT_ID!]: 5000,
  [process.env.NEXT_PUBLIC_STRIPE_SENIOR_PRODUCT_ID!]: 10000,
  [process.env.NEXT_PUBLIC_STRIPE_SCALE_PRODUCT_ID!]: 10000,
  [process.env.NEXT_PUBLIC_STRIPE_SENIOR_PLUS_PRODUCT_ID!]: 20000,
  [process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PRODUCT_ID!]: 30000,
  [process.env.NEXT_PUBLIC_STRIPE_ENTERPRISE_PRODUCT_ID!]: 30000,
} as const;

const sdrLimitMatrix = {
  [UserSubscription.FREEMIUM]: 4,
  [UserSubscription.JUNIOR]: 15,
  [UserSubscription.JUNIOR_PLUS]: Number.MAX_SAFE_INTEGER,
  [UserSubscription.SENIOR]: Number.MAX_SAFE_INTEGER,
  [UserSubscription.SENIOR_PLUS]: Number.MAX_SAFE_INTEGER,
  [UserSubscription.ENTERPRISE]: Number.MAX_SAFE_INTEGER,
  [UserSubscription.SOCIAL_SIGNAL]: Number.MAX_SAFE_INTEGER,
  [UserSubscription.GROWTH]: Number.MAX_SAFE_INTEGER,
  [UserSubscription.SCALE]: Number.MAX_SAFE_INTEGER,
  [UserSubscription.CUSTOM]: Number.MAX_SAFE_INTEGER,
} as const;

const companyLimitMatrix = {
  [UserSubscription.FREEMIUM]: 0,
  [UserSubscription.JUNIOR]: 250,
  [UserSubscription.JUNIOR_PLUS]: 250,
  [UserSubscription.SENIOR]: 1000,
  [UserSubscription.SENIOR_PLUS]: 5_000,
  [UserSubscription.ENTERPRISE]: 10_000,
  [UserSubscription.SOCIAL_SIGNAL]: 10_000,
  [UserSubscription.GROWTH]: 10_000,
  [UserSubscription.SCALE]: 10_000,
  [UserSubscription.CUSTOM]: 10_000,
} as const;

const csvMatrix = {
  [UserSubscription.FREEMIUM]: 0,
  [UserSubscription.JUNIOR]: 1000,
  [UserSubscription.JUNIOR_PLUS]: 1000,
  [UserSubscription.SENIOR]: 1000,
  [UserSubscription.SENIOR_PLUS]: 1000,
  [UserSubscription.ENTERPRISE]: 1000,
  [UserSubscription.SOCIAL_SIGNAL]: 1000,
  [UserSubscription.GROWTH]: 1000,
  [UserSubscription.SCALE]: 1000,
  [UserSubscription.CUSTOM]: 1000,
} as const;

const staticPeopleLimits = {
  [UserSubscription.FREEMIUM]: 50,
  [UserSubscription.JUNIOR]: 1000,
  [UserSubscription.JUNIOR_PLUS]: 1000,
  [UserSubscription.SENIOR]: 2500,
  [UserSubscription.SENIOR_PLUS]: 5_000,
  [UserSubscription.ENTERPRISE]: 10_000,
  [UserSubscription.SOCIAL_SIGNAL]: 10_000,
  [UserSubscription.GROWTH]: 10_000,
  [UserSubscription.SCALE]: 10_000,
  [UserSubscription.CUSTOM]: 10_000,
};

const staticJobListLimits = {
  [UserSubscription.FREEMIUM]: 50,
  [UserSubscription.JUNIOR]: 1000,
  [UserSubscription.JUNIOR_PLUS]: 1000,
  [UserSubscription.SENIOR]: 2500,
  [UserSubscription.SENIOR_PLUS]: 2500,
  [UserSubscription.ENTERPRISE]: 5000,
  [UserSubscription.SOCIAL_SIGNAL]: 5000,
  [UserSubscription.GROWTH]: 5000,
  [UserSubscription.SCALE]: 5000,
  [UserSubscription.CUSTOM]: 5000,
};
// TODO: Change
// const staticPeopleLimits = {
//   [UserSubscription.FREEMIUM]: 0,
//   [UserSubscription.JUNIOR]: 30,
//   [UserSubscription.JUNIOR_PLUS]: 30,
//   [UserSubscription.SENIOR]: 30,
//   [UserSubscription.SENIOR_PLUS]: 30,
//   [UserSubscription.ENTERPRISE]: 30,
// };

const freeTrialStaticAmount = 250;

const companyStaticLimits = {
  [UserSubscription.FREEMIUM]: 50,
  [UserSubscription.JUNIOR]: 1000,
  [UserSubscription.JUNIOR_PLUS]: 1000,
  [UserSubscription.SENIOR]: 2500,
  [UserSubscription.SENIOR_PLUS]: 5_000,
  [UserSubscription.ENTERPRISE]: 10_000,
  [UserSubscription.SOCIAL_SIGNAL]: 10_000,
  [UserSubscription.GROWTH]: 10_000,
  [UserSubscription.SCALE]: 10_000,
  [UserSubscription.CUSTOM]: 10_000,
};
// TODO: Change

const jobCountMatrix = {
  [UserSubscription.FREEMIUM]: 50,
  [UserSubscription.JUNIOR]: 5000,
  [UserSubscription.JUNIOR_PLUS]: 5000, // TODO: review limit
  [UserSubscription.SENIOR]: 5000,
  [UserSubscription.SENIOR_PLUS]: 5_000,
  [UserSubscription.ENTERPRISE]: 10_000,
  [UserSubscription.SOCIAL_SIGNAL]: 10_000,
  [UserSubscription.GROWTH]: 10_000,
  [UserSubscription.SCALE]: 10_000,
  [UserSubscription.CUSTOM]: 10_000,
} as const;

const abmAssistantsMatrix = {
  [UserSubscription.FREEMIUM]: 0,
  [UserSubscription.JUNIOR]: 1,
  [UserSubscription.JUNIOR_PLUS]: 1,
  [UserSubscription.SENIOR]: 1,
  [UserSubscription.SENIOR_PLUS]: 1,
  [UserSubscription.ENTERPRISE]: 5,
  [UserSubscription.SOCIAL_SIGNAL]: 5,
  [UserSubscription.GROWTH]: 5,
  [UserSubscription.SCALE]: 5,
  [UserSubscription.CUSTOM]: 5,
} as const;

const addonsToPlanMatrix = {
  [UserSubscription.SOCIAL_SIGNAL]: [process.env.NEXT_PUBLIC_STRIPE_WEBSITE_IDENTIFICATION_PRODUCT_ID],
  [UserSubscription.GROWTH]: [
    process.env.NEXT_PUBLIC_STRIPE_JOB_CHANGES_PRODUCT_ID,
    process.env.NEXT_PUBLIC_STRIPE_JOB_POSTINGS_PRODUCT_ID,
    process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SEARCH_PRODUCT_ID,
    process.env.NEXT_PUBLIC_STRIPE_WEBSITE_IDENTIFICATION_PRODUCT_ID,
    process.env.NEXT_PUBLIC_STRIPE_ENABLEMENT_INTEGRATIONS_PRODUCT_ID,
    process.env.NEXT_PUBLIC_STRIPE_CRM_SYNC_PRODUCT_ID,
  ],
  [UserSubscription.SCALE]: [
    process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SEARCH_PRODUCT_ID,
    process.env.NEXT_PUBLIC_STRIPE_WEBSITE_IDENTIFICATION_PRODUCT_ID,
  ],
  [UserSubscription.CUSTOM]: [process.env.NEXT_PUBLIC_STRIPE_WEBSITE_IDENTIFICATION_PRODUCT_ID],
} as Record<UserSubscription, string[]>;

export class SubscriptionService {
  private readonly FREE_TRIAL_CREDITS = 100;
  private readonly COST_PER_SCRIPT = 2;
  private readonly TRIAL_PERIOD_DAYS = 7;
  private readonly UPGRADE_COMMENT = "User has upgraded their subscription";

  constructor(
    private readonly stripe: Stripe,
    private readonly db: PrismaClient,
    private readonly clerk: typeof clerkClient,
    private readonly posthog?: PosthogService,
    private readonly inngest?: Inngest,
    private readonly logger?: winston.Logger,
    private readonly slackService?: SlackService,
  ) {}

  public determineUserSubscription(
    productId: Stripe.Subscription["items"]["data"][0]["price"]["product"],
  ): UserSubscription | null {
    console.log("[Subscription Service]", { productId });
    switch (productId) {
      case process.env.NEXT_PUBLIC_STRIPE_JUNIOR_PRODUCT_ID:
        return UserSubscription.JUNIOR;
      case process.env.NEXT_PUBLIC_STRIPE_JUNIOR_PLUS_PRODUCT_ID:
        return UserSubscription.JUNIOR_PLUS;
      case process.env.NEXT_PUBLIC_STRIPE_SENIOR_PRODUCT_ID:
        return UserSubscription.SENIOR;
      case process.env.NEXT_PUBLIC_STRIPE_SENIOR_PLUS_PRODUCT_ID:
        return UserSubscription.SENIOR_PLUS;
      case process.env.NEXT_PUBLIC_STRIPE_ENTERPRISE_PRODUCT_ID:
        return UserSubscription.ENTERPRISE;
      case process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SIGNAL_PRODUCT_ID:
        return UserSubscription.SOCIAL_SIGNAL;
      case process.env.NEXT_PUBLIC_STRIPE_GROWTH_PRODUCT_ID:
        return UserSubscription.GROWTH;
      case process.env.NEXT_PUBLIC_STRIPE_SCALE_PRODUCT_ID:
        return UserSubscription.SCALE;
      case process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PRODUCT_ID:
        return UserSubscription.CUSTOM;
      default:
        return null;
    }
  }

  public static getFixedPrice(type: "email" | "phone") {
    if (type === "email") {
      return 0.031;
    }
    return 0.31;
  }

  public static determinePhoneLimit(sub: UserSubscription) {
    return matrix.metered.phone[sub];
  }

  public static determineFreemiumEmailLimit() {
    return 200;
  }

  public static determineEmailLimit(sub: UserSubscription) {
    return matrix.metered.email[sub];
  }

  public static getStaticPersonListSize(sub: UserSubscription, freeTrial = false) {
    if (process.env.NODE_ENV === "development") {
      return 10;
    }
    if (freeTrial) {
      // return 1000; // todo: revert
      return freeTrialStaticAmount;
    }
    return staticPeopleLimits[sub];
  }

  public static getStaticJobListSize(sub: UserSubscription, freeTrial = false) {
    if (process.env.NODE_ENV === "development") {
      return 10;
    }
    if (freeTrial) {
      return freeTrialStaticAmount;
      // return 10;
    }
    return staticJobListLimits[sub];
  }

  public static getStaticCompanyListSize(sub: UserSubscription, freeTrial = false) {
    if (process.env.NODE_ENV === "development") {
      return 10;
    }
    if (freeTrial) {
      return freeTrialStaticAmount;
      // return 10;
    }
    return companyStaticLimits[sub];
  }

  public static getStripeIdsForUserSubscription(sub: UserSubscription) {
    const map = {
      [UserSubscription.SOCIAL_SIGNAL]: {
        priceId: process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SIGNAL_PRICE_ID,
        productId: process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SIGNAL_PRODUCT_ID,
        phonePriceId: process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SIGNAL_PHONE_PRICE_ID,
        emailPriceId: process.env.NEXT_PUBLIC_STRIPE_SOCIAL_SIGNAL_EMAIL_PRICE_ID,
      },
      [UserSubscription.GROWTH]: {
        priceId: process.env.NEXT_PUBLIC_STRIPE_GROWTH_PRICE_ID,
        productId: process.env.NEXT_PUBLIC_STRIPE_GROWTH_PRODUCT_ID,
        phonePriceId: process.env.NEXT_PUBLIC_STRIPE_GROWTH_PHONE_PRICE_ID,
        emailPriceId: process.env.NEXT_PUBLIC_STRIPE_GROWTH_EMAIL_PRICE_ID,
      },
      [UserSubscription.SCALE]: {
        priceId: process.env.NEXT_PUBLIC_STRIPE_SCALE_PRICE_ID,
        productId: process.env.NEXT_PUBLIC_STRIPE_SCALE_PRODUCT_ID,
        phonePriceId: process.env.NEXT_PUBLIC_STRIPE_SCALE_PHONE_PRICE_ID,
        emailPriceId: process.env.NEXT_PUBLIC_STRIPE_SCALE_EMAIL_PRICE_ID,
      },
      [UserSubscription.CUSTOM]: {
        priceId: process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PRICE_ID,
        productId: process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PRODUCT_ID,
        phonePriceId: process.env.NEXT_PUBLIC_STRIPE_CUSTOM_PHONE_PRICE_ID,
        emailPriceId: process.env.NEXT_PUBLIC_STRIPE_CUSTOM_EMAIL_PRICE_ID,
      },
    } as Record<
      UserSubscription,
      {
        priceId: string;
        productId: string;
        phonePriceId: string;
        emailPriceId: string;
      }
    >;
    return map[sub];
  }

  public static getFreeTrialDays() {
    return 7;
  }

  public static determineCSVLimit = (plan?: UserSubscription) => {
    return plan ? csvMatrix[plan] : 0;
  };

  public static determineABMAssistants(plan?: UserSubscription) {
    return plan ? abmAssistantsMatrix[plan] : 0;
  }

  public static determineCompanyLimit(sub?: UserSubscription) {
    return sub ? companyLimitMatrix[sub] : 0;
  }
  public static determineSdrLimit(sub?: UserSubscription) {
    return sub ? sdrLimitMatrix[sub] : 0;
  }
  public static determineJobCount(sub?: UserSubscription) {
    if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
      return 10;
    }

    return sub ? jobCountMatrix[sub] : 0;
  }

  public static determineSmartTagLimit(sub: UserSubscription) {
    return matrix.smartTags[sub];
  }

  public static determineLinkedinUsernameLimit(sub: UserSubscription) {
    return matrix.linkedinUserNames[sub];
  }

  public static determineListLimit(sub: UserSubscription) {
    return sdrLimitMatrix[sub];
  }

  public static determineCreditValue(sub: UserSubscription) {
    return creditMatrix[sub];
  }

  public determineThresholdViaSub(sub: UserSubscription) {
    return creditMatrix[sub];
  }

  public determinRequestThreshold(
    productId: Stripe.Subscription["items"]["data"][0]["price"]["product"],
    isFreeTrial = false,
  ) {
    console.log("[Subscription Service]", { productId, isFreeTrial });
    if (isFreeTrial) {
      return this.FREE_TRIAL_CREDITS;
    }
    return creditMatrix[productId as keyof typeof creditMatrix];
  }

  private costPerScript() {
    return this.COST_PER_SCRIPT;
  }

  public async checkCreditsForCoreSignalJobList(userId: string, sub: UserSubscription, limit: number) {
    const cost = limit * matrix.static.coresignal[sub];

    const { quantity } = await this.getCreditQuantity(userId);

    if (quantity < cost) {
      // throw a 429
      throw new PaymentRequiredError("Payment Required, please top up");
    }

    return {
      isValid: true,
      creditsAvailable: quantity,
    };
  }

  public async checkCreditsForPeopleList(userId: string, limit: number) {
    const user = await this.getUserWithSubscription(userId);

    if (!user?.subscription_plan && !user?.is_free_trial) {
      throw new PaymentRequiredError("User not subscribed, please subscribe");
    }

    const { quantity } = await this.getCreditQuantity(userId);

    const cost = limit * this.costPerLead(user.subscription_plan!, "static", "people");

    if (quantity < cost) {
      // throw a 429
      throw new PaymentRequiredError("Payment Required, please top up");
    }

    return {
      isValid: true,
      creditsAvailable: quantity,
    };
  }

  public async checkCreditsForCompanyEvents(userId: string, limit: number) {
    const user = await this.getUserWithSubscription(userId);

    if (!user?.subscription_plan && !user?.is_free_trial) {
      throw new PaymentRequiredError("User not subscribed, please subscribe");
    }

    const { quantity } = await this.getCreditQuantity(userId);

    const cost = limit * this.costPerLead(user.subscription_plan!, "abm", "events");

    if (quantity < cost) {
      // throw a 429
      throw new PaymentRequiredError("Payment Required, please top up");
    }

    return {
      isValid: true,
      creditsAvailable: quantity,
    };
  }

  public async checkCreditsForAiScript(userId: string, numberOfEmails: number) {
    if (process.env.NODE_ENV === "development") {
      return {
        isValid: true,
        creditsAvailable: Number.MAX_SAFE_INTEGER,
      };
    }

    const user = await this.getUserWithSubscription(userId);

    if (!user?.subscription_plan && !user?.is_free_trial) {
      throw new PaymentRequiredError("User not subscribed, please subscribe");
    }

    const price = numberOfEmails * this.costPerScript();

    const { quantity } = await this.getCreditQuantity(userId);
    if (quantity < price) {
      // throw a 429
      throw new PaymentRequiredError("Payment Required, please top up");
    }
    return {
      isValid: true,
      creditsAvailable: quantity,
    };
  }

  public async getSubscription(subId: string) {
    const sub = subId ? await this.stripe.subscriptions.retrieve(subId) : null;

    return sub;
  }
  private getSubscriptionItem(subscription: Stripe.Subscription): Stripe.SubscriptionItem {
    return subscription.items.data[0]!;
  }

  public static determineCostPerPredictLeadsEvent(sub: UserSubscription) {
    return matrix.abm.events[sub];
  }

  public static determineCostPerStaticCompany(sub: UserSubscription) {
    return matrix.abm.companies[sub];
  }

  public static determineCostPerStaticCoresignalJob(sub: UserSubscription) {
    return matrix.static.coresignal[sub];
  }

  public static determineCostPerStaticATSJob(sub: UserSubscription) {
    return matrix.static.coresignal[sub];
  }

  public static determineCostPerEnrichment(sub: UserSubscription) {
    return matrix.shared.personEnrich[sub];
  }

  public static determineTrigIQCost(sub: UserSubscription) {
    return matrix.shared.aiScript[sub];
  }

  public static determinCostPerStaticLead(sub: UserSubscription) {
    return matrix.static.people[sub];
  }

  public static determineCostPerNewInRole(sub: UserSubscription) {
    return matrix.shared.personEnrich[sub];
  }

  public static getCreateOrgAddon(addons: subscription_item[]) {
    const productId = process.env.NEXT_PUBLIC_STRIPE_CREATE_ORGANISATION_PRODUCT_ID;
    return addons.find((addon) => addon.product_id === productId);
  }

  public static canEnrichPeople(sub: UserSubscription) {
    if (!sub) {
      return false;
    }
    const allowedSubs = [
      UserSubscription.JUNIOR,
      UserSubscription.JUNIOR_PLUS,
      UserSubscription.SENIOR,
      UserSubscription.SENIOR_PLUS,
      UserSubscription.ENTERPRISE,
      UserSubscription.CUSTOM,
      UserSubscription.SCALE,
    ] as UserSubscription[];

    return allowedSubs.includes(sub);
  }

  public costPerLead<TTrigger extends keyof typeof matrix>(
    sub: UserSubscription,
    trigger: TTrigger,
    tranche: keyof (typeof matrix)[TTrigger],
  ): number {
    // @ts-expect-error -- ignore
    return matrix[trigger][tranche][sub] as number;
  }

  private costPerPhone(sub: UserSubscription): number {
    return matrix.shared.phone[sub] as number;
  }

  private costPerEmail(sub: UserSubscription): number {
    return matrix.shared.email[sub] as number;
  }

  public static getCostPerAiScript(sub: UserSubscription): number {
    return matrix.shared.aiScript[sub];
  }

  public async handleInvoicePaid(event: Stripe.InvoicePaidEvent) {
    const sub = await this.getSubscription(event.data.object.subscription as string);

    const item = this.getSubscriptionItem(sub!);
    const user = await this.db.account_user.findUnique({
      where: {
        email: event.data.object.customer_email!,
      },
      select: {
        credits: true,
        user_id: true,
      },
    });

    if (!user) {
      console.warn("User not found", event.data.object.customer_email);
      return false;
      // todo: ping slack
    }

    const isFreeTrial = !!sub?.trial_end;

    const credits = this.determinRequestThreshold(item?.price.product as string, isFreeTrial);

    console.log("DATA", {
      product: item?.price.product,
      credits,
    });

    const isYearly = item.price?.recurring?.interval === "year";

    const nextReset =
      isFreeTrial || isYearly ? addOneMonthStartOfDay() : sub ? new Date(sub.current_period_end) : new Date();

    await this.db.credits.upsert({
      where: {
        id: user.credits?.id ?? "",
      },
      create: {
        credits: credits,
        id: shortId(),
        account_user: {
          connect: {
            user_id: user.user_id,
          },
        },
        next_reset: nextReset,
        receipts: {
          create: {
            action: CreditAction.CREDIT,
            id: shortId(),
            credits: credits,
            user_id: user.user_id,
          },
        },
      },
      update: {
        credits: {
          increment: credits,
        },
        next_reset: nextReset,
        receipts: {
          create: {
            action: CreditAction.CREDIT,
            id: shortId(),
            credits: credits,
            user_id: user.user_id,
          },
        },
      },
    });

    return true;
  }

  public async getCreditQuantity(userId: string) {
    const user = await this.db.account_user.findUnique({
      where: {
        user_id: userId,
      },
      select: {
        is_free_trial: true,
        credits: true,
        subscription_plan: true,
      },
    });

    if (!user?.credits) {
      throw new PaymentRequiredError("User not subscribed, please subscribe");
    }

    const total = !user.is_free_trial
      ? this.determineThresholdViaSub(user.subscription_plan!)
      : this.FREE_TRIAL_CREDITS;

    const qty = new Prisma.Decimal(user?.credits.credits).toNumber();
    const percentage = (qty / (total ?? 0)) * 100;

    if (percentage <= 30) {
      this.posthog?.track(PostHogEvents.CREDIT_USAGE_WARNING, userId, {
        userId,
        credits: qty,
        percentage,
      });
    }

    if (percentage === 0) {
      this.posthog?.track(PostHogEvents.CREDIT_USAGE_ERROR, userId, {
        userId,
        credits: qty,
        percentage,
      });
    }

    return {
      percentage,
      quantity: qty,
      total,
    };
  }
  /**
   * @param userId The user id
   * @returns {stripe_metered_sub_item_id: string, subscription_plan: UserSubscription}
   *
   * @description
   *
   * - Gets the user from the database
   * - Returns the stripe_metered_sub_item_id and the subscription_plan
   */
  private async getUserWithSubscription(userId: string) {
    return this.db.account_user.findUnique({
      where: {
        user_id: userId,
      },
      select: {
        stripe_metered_sub_item_id: true,
        subscription_plan: true,
        credits: true,
        stripe_customer_id: true,
        is_free_trial: true,
      },
    });
  }

  /**
   *
   * @param useId The userID
   * @param numberOfLeads The number of leads to check for
   * @returns  {isValid: boolean, creditsAvailable: number}
   *
   * @description
   *
   * - Checks if the user has enough credits to enrich the number of leads
   * - If the user does not have enough credits, throw a 429
   * - If the user does not have a subscription, throw a 402
   * - If the user has enough credits, return the number of credits available
   *
   * @returns  {PaymentRequiredError}
   */
  public async checkCreditsForLeads<TTrigger extends keyof typeof matrix>(
    userId: string,
    numberOfLeads: number,
    trigger: TTrigger = "hiring" as TTrigger,
    tranche: keyof (typeof matrix)[TTrigger] = "prospecting" as keyof (typeof matrix)[TTrigger],
  ) {
    const user = await this.getUserWithSubscription(userId);
    const creditsNeeded = numberOfLeads * this.costPerLead(user?.subscription_plan!, trigger, tranche);

    const { quantity, total } = await this.getCreditQuantity(userId);

    console.log(quantity, total, creditsNeeded);

    if (quantity < creditsNeeded) {
      // throw a 429
      throw new PaymentRequiredError("Payment Required, please top up");
    }

    return {
      isValid: true,
      creditsAvailable: quantity,
    };
  }

  /**
   *
   * @param userId Id of the user
   * @param numberOfEmails Number of emails to check for
   *
   * @description
   *
   * - Checks if the user has enough credits to enrich the number of emails
   * - If the user does not have enough credits, throw a 429
   * - If the user does not have a subscription, throw a 402
   * - If the user has enough credits, return the number of credits available
   *
   *
   * @returns {isValid: boolean, creditsAvailable: number}
   */
  public async checkCreditsForEmailOrPhoneEnrichment(
    userId: string,
    numberOfEmails: number,
    type: "email" | "phone" = "email",
  ) {
    if (process.env.NODE_ENV === "development") {
      return {
        isValid: true,
        creditsAvailable: Number.MAX_SAFE_INTEGER,
      };
    }

    const user = await this.getUserWithSubscription(userId);

    if (!user?.subscription_plan && !user?.is_free_trial) {
      throw new PaymentRequiredError("User not subscribed, please subscribe");
    }

    const price =
      numberOfEmails *
      (type === "email" ? this.costPerEmail(user.subscription_plan!) : this.costPerPhone(user.subscription_plan!));

    const { quantity } = await this.getCreditQuantity(userId);
    if (quantity < price) {
      // throw a 429
      throw new PaymentRequiredError("Payment Required, please top up");
    }
    return {
      isValid: true,
      creditsAvailable: quantity,
    };
  }

  private async checkAndRestartSocialSignalsSync(user: account_user) {
    // check for a linkedin connection
    this.logger?.info("Checking for linkedin connection: ", user.user_id);
    const connection = await this.db.linkedin_connection.findFirst({
      where: {
        user_id: user.user_id,
      },
      include: {
        li_usernames: true,
      },
    });

    if (!connection) {
      this.logger?.info(`No linkedin connection found for user ${user.user_id}`);
      return;
    }

    if (!user.organisation_id) {
      this.logger?.info(`No organisation found for user ${user.user_id}, skipping linkedin sync`);
      return;
    }

    const liUsernames = connection.li_usernames;
    this.logger?.info(`Found linkedin connection for user ${user.user_id}`, liUsernames);

    const events = liUsernames.map<IntegrationEvents["integrations/seed-linkedin"]>((e) => ({
      data: {
        connectionId: connection.id,
        linkedInUserId: e.id,
        organisationId: user.organisation_id!,
        userId: user.user_id,
        username: e.username,
      },
      name: "integrations/seed-linkedin",
    }));
    await this.inngest?.send(events);

    this.logger?.info("Sent linkedin events to inngest", events);
  }

  async createCustomer(email: string, name: string, referralCode?: string) {
    const exists = await this.getCustomer(email);
    if (exists) {
      return exists;
    }

    return this.stripe.customers.create({
      name,
      email,
      ...(referralCode && {
        metadata: {
          referral: referralCode,
        },
      }),
    });
  }

  async updateCustomerMetadata<TData extends Record<string, unknown>>(clerkUserId: string, data: TData) {
    return this.clerk.users.updateUser(clerkUserId, {
      publicMetadata: {
        ...data,
      },
    });
  }

  async sendInviteEmail(email: string) {
    return await this.clerk.invitations.createInvitation({
      emailAddress: email,
      redirectUrl: process.env.NEXT_PUBLIC_INVITE_URL!,
      ignoreExisting: true,
      publicMetadata: {
        onboarded: false,
      },
    });
  }

  async getCustomer(email: string) {
    const customers = await this.stripe.customers.list({
      email,
      expand: ["data.subscriptions"],
    });

    return customers.data[0];
  }

  /**
   * Retrieves the metered usage for a specific user and flow type.
   *
   * @param userId - The ID of the user.
   * @param type - The flow type, which can be either "phone" or "email".
   * @returns An object containing the metered usage information.
   */
  public async getMeteredUsage<TFlow extends "phone" | "email">(userId: string, type: TFlow) {
    const user = await this.getUserWithSubscription(userId);
    const totalFree = user?.is_free_trial
      ? SubscriptionService.determineFreemiumEmailLimit()
      : matrix.metered[type][user?.subscription_plan!];

    const meterId =
      type === "phone" ? process.env.NEXT_PUBLIC_STRIPE_PHONE_METER_ID : process.env.NEXT_PUBLIC_STRIPE_EMAIL_METER_ID;

    const usage = await this.stripe.billing.meters.listEventSummaries(meterId!, {
      customer: user?.stripe_customer_id!,
      start_time: dayjs().subtract(1, "month").unix(),
      end_time: dayjs().unix(),
      limit: 1,
    });

    this.logger?.info(`Got usage for user ${userId}`, usage);

    const mostRecentUsage = usage.data[0];
    const hasExceededFreeUsage = (mostRecentUsage?.aggregated_value ?? 0) > totalFree;

    return {
      ...mostRecentUsage,
      freeUsage: totalFree,
      hasExceededFreeUsage: hasExceededFreeUsage,
    };
  }

  /**
   * Increases the metered usage for a user.
   *
   * @param userId - The ID of the user.
   * @param quantity - The quantity to increase the usage by.
   * @param type - The type of flow, either "phone" or "email".
   * @returns A Promise that resolves to the created meter event.
   * @throws If the user does not have a stripe_customer_id or if there is an error increasing the usage.
   */
  public async increaseMeteredUsage<TFlow extends "phone" | "email">(userId: string, quantity: number, type: TFlow) {
    try {
      const event = type === "phone" ? "phone_enrichment" : "email_enrichment";
      const accountUser = await this.db.account_user.findFirst({
        where: {
          user_id: userId,
        },
      });

      this.logger?.info(`Increasing usage for user ${userId} by ${quantity} for ${type}`);

      if (!accountUser?.stripe_customer_id) {
        throw new Error("User does not have a stripe_customer_id");
      }

      return await this.stripe.billing.meterEvents.create({
        event_name: event,
        timestamp: dayjs().unix(),
        payload: {
          value: quantity.toString(),
          stripe_customer_id: accountUser?.stripe_customer_id,
        },
      });
    } catch (error) {
      this.logger?.error("Failed to increase usage", error);
      throw error;
    }
  }

  public async increaseUsage<TFlow extends keyof typeof matrix>(
    userId: string,
    quantity: number,
    flow: TFlow,
    tranche: keyof (typeof matrix)[TFlow],
  ) {
    const user = await this.getUserWithSubscription(userId);
    const cost =
      tranche !== "email"
        ? this.costPerLead(user?.subscription_plan!, flow, tranche)
        : tranche === "email"
          ? this.costPerEmail(user?.subscription_plan!)
          : tranche === "phone"
            ? this.costPerPhone(user?.subscription_plan!)
            : 0;

    return await this.db.$transaction([
      this.db.credits.update({
        where: {
          id: user?.credits?.id,
        },
        data: {
          credits: {
            decrement: quantity * cost,
          },
        },
      }),
    ]);
  }

  /**
   * Determines the addons to carry over for a user's next subscription based on the next product ID.
   * It checks if the user has addons currently that are in the next tier and returns the ones that match it.
   *
   * @param items - The array of subscription items.
   * @param nextSubscription - The user's next subscription.
   * @returns An array of subscription items that match the addons in the next tier.
   */
  public determineAddonsToCarryOver(items: subscription_item[], nextSubscription: UserSubscription) {
    // based on next product ID, check if the user has addons currently that are in the next tier..
    //and return the ones that match it
    const newAddons = addonsToPlanMatrix[nextSubscription];

    this.logger?.info(`Checking addons to carry over for user: ${newAddons.join(", ")}`, items);

    const allNewItems = items.filter((item) => newAddons.includes(item.product_id));

    const filteredOutNoNames = allNewItems.filter((item) => item.name !== "");

    // check if the current user has the new addons in their current subscription and return them

    return filteredOutNoNames;
  }

  public async cancelExistingSubscription(subId: string, comment?: string) {
    return this.stripe.subscriptions.cancel(subId, {
      cancellation_details: {
        comment: comment ?? this.UPGRADE_COMMENT,
      },
    });
  }

  /**
   * Handles the completion of a checkout session.
   *
   * @param event - The Stripe.CheckoutSessionCompletedEvent object representing the completed checkout session.
   * @returns An object containing the user's subscription and the Stripe subscription object.
   * @throws An error if there is no subscription found or if there is a failure in creating the user.
   */
  public async handleCheckoutComplete(event: Stripe.CheckoutSessionCompletedEvent) {
    try {
      let clerkUser: User | null = null;
      try {
        clerkUser = await this.clerk.users.getUser(event.data.object.client_reference_id!);
      } catch (error) {
        this.logger?.warn(`Failed to get clerk user for user ${event.data.object.customer_details?.email}`);
      }

      this.logger?.info(`Checkout completed for user ${event.data.object.customer_details?.email}`);

      if (!event.data.object.subscription) {
        throw new Error("No subscription found");
      }

      const user = await this.db.account_user.findUnique({
        where: {
          email: event.data.object.customer_details?.email ?? "",
        },
        include: {
          credits: true,
        },
      });

      if (!user) {
        // remove subscription
        await this.cancelExistingSubscription(event.data.object.subscription as string);
        throw new Error("No user found, cancelling subscription");
      }

      const sub = await this.stripe.subscriptions.retrieve(event.data.object.subscription as string);

      if (user?.stripe_subscription_id && user?.stripe_customer_id) {
        const customer = await this.stripe.customers.retrieve(user.stripe_customer_id);
        const subs = await this.stripe.subscriptions.list({
          customer: customer.id,
          status: "active",
        });

        if (subs.data.length > 1) {
          // if the current sub was created over a week ago, cancel it
          await this.cancelExistingSubscription(user?.stripe_subscription_id);
        }
      }

      const item = this.getSubscriptionItem(sub);

      const isYearly = item.price?.recurring?.interval === "year";

      const subPlan = this.determineUserSubscription((item?.price?.product as string) ?? "");

      if (!clerkUser) {
        clerkUser = await this.clerk.users.createUser({
          password: process.env.CLERK_DEFAULT_PASSWORD!,
          emailAddress: [event.data.object.customer_details?.email ?? ""],
          firstName: event.data.object.customer_details?.name?.split(" ")[0] ?? "",
          lastName: event.data.object.customer_details?.name?.split(" ")[1] ?? "",
        });
      }
      const updatedUser = await this.db.account_user.upsert({
        where: {
          email: event.data.object.customer_details?.email ?? "",
        },
        update: {
          subscription_interval: isYearly ? SubscriptionInterval.YEARLY : SubscriptionInterval.MONTHLY,
          stripe_customer_id: event.data.object.customer as string,
          stripe_subscription_id: sub.id,
          subscription_plan: this.determineUserSubscription((sub.items.data[0]?.price?.product as string) ?? ""),
          is_free_trial: false,
          is_active: true,
          trial_end_date: new Date(),
        },
        create: {
          trial_end_date: new Date(),
          date_joined: new Date(),
          email: event.data.object.customer_details?.email ?? null,
          last_name: event.data.object.customer_details?.name?.split(" ")[1] ?? "",
          first_name: event.data.object.customer_details?.name?.split(" ")[0] ?? "",
          is_active: true,
          is_staff: false,
          is_superuser: false,
          subscription_interval: isYearly ? SubscriptionInterval.YEARLY : SubscriptionInterval.MONTHLY,
          is_free_trial: false,
          password: "",
          subscription_plan: subPlan,
          user_id: clerkUser.id,
          stripe_subscription_id: sub.id,
          stripe_customer_id: event.data.object.customer as string,
        },
      });

      // Track this event
      this.posthog?.track(PostHogEvents.BOUGHT_SUBSCRIPTION, updatedUser.user_id, {
        email: updatedUser.email,
        firstName: updatedUser.first_name,
        lastName: updatedUser.last_name,
        subscriptionPlan: subPlan,
        interval: updatedUser.subscription_interval,
        freeTrial: false,
        creditsBought: this.determineThresholdViaSub(subPlan!),
        stripeCustomerId: event.data.object.customer as string,
        stripeSubscriptionId: sub.id,
      });

      if (
        user.subscription_plan === UserSubscription.FREEMIUM &&
        updatedUser.subscription_plan !== UserSubscription.FREEMIUM
      ) {
        // if the user has upgraded from freemium.. check and restart social signals sync
        await this.checkAndRestartSocialSignalsSync(updatedUser);
      }

      return {
        userSubscription: subPlan,
        stripeSubscription: sub,
      };
    } catch (error) {
      console.error(error);
      const err = error as Error;
      throw new Error(err.message ?? "Failed to create user");
    }
  }

  /**
   * Creates package addons from a subscription.
   *
   * @description
   *  - Goes through each item in the subscription and creates a package addon.
   *  - If the user has existing addons, they are deleted and recreated.
   *
   *
   * @param subscription - The Stripe subscription object.
   * @param userId - The ID of the user.
   * @returns An array of created addons.
   */
  private async createPackageAddonsFromSubscription(subscription: Stripe.Subscription, userId: string) {
    // go through each item in the subscription and create a package addon
    const items = subscription.items.data;

    console.log("ITEMS", items);
    console.log("userId", userId);

    // check to see if the user has existing addons
    const existingAddons = await this.db.subscription_item.findMany({
      where: {
        user_id: userId,
      },
    });

    // if the user has existing addons, delete them .. we will recreate them
    if (existingAddons.length > 0) {
      await this.db.subscription_item.deleteMany({
        where: {
          user_id: userId,
        },
      });
    }

    const addons = await Promise.all(
      items.map(async (item) => {
        const addon = await this.db.subscription_item.create({
          data: {
            name: item.price.nickname ?? "",
            price: item.price.unit_amount ?? 0,
            quantity: item.quantity ?? 1,
            price_id: item.price.id,
            product_id: item.price.product as string,
            user_id: userId,
          },
        });
        return addon;
      }),
    );

    return addons;
  }

  /**
   * Handles the event when a customer subscription is updated or created.
   *
   * @param event - The event object containing the subscription update or creation details.
   * @returns A boolean indicating the success of the operation.
   * @throws Error if the subscription or user is not found.
   */
  public async handleSubscriptionUpdated(
    event: Stripe.CustomerSubscriptionUpdatedEvent | Stripe.CustomerSubscriptionCreatedEvent,
  ) {
    this.logger?.info(`Subscription updated for user ${event.data.object.customer as string}`);

    const sub = await this.getSubscription(event.data.object.id);

    const item = this.getSubscriptionItem(sub!);

    const user = await this.db.account_user.findFirst({
      where: {
        stripe_customer_id: event.data.object.customer as string,
      },
      include: {
        credits: true,
      },
    });

    this.logger?.info(
      `Checking for cancellation details for user ${user?.email} -- ${event.data.object.cancellation_details?.reason}`,
    );

    if (!!event.data.object.cancellation_details?.reason && user) {
      this.logger?.info(
        `Subscription cancelled for user ${user.email} -- ${event.data.object.cancellation_details?.reason}`,
      );
      await this.slackService?.sendSubscriptionCancellationMessage(user, {
        ...event.data.object,
        userSubscription: user.subscription_plan!,
      });
    }

    if (!sub) {
      throw new Error(`Subscription not found -- Failed to update subscription for subId ${event.data.object.id}`);
    }

    if (!user) {
      throw new Error(`User not found -- Failed to update subscription for subId ${event.data.object.id}`);
    }

    this.logger?.info(`Creating ${sub.items.data.length} package addons for user ${user.email}`);
    const addons = await this.createPackageAddonsFromSubscription(sub, user.user_id);

    this.logger?.info(`Created ${addons.length} package addons for user ${user.email}`);

    const newPlan = this.determineUserSubscription(item?.price.product as string);

    await this.db.account_user.update({
      where: {
        user_id: user.user_id,
      },
      data: {
        cancel_at: event.data.object.cancel_at ? new Date(event.data.object.cancel_at * 1000) : null,
        stripe_subscription_id: event.data.object.id,
        subscription_plan: newPlan ? newPlan : user.subscription_plan,
      },
    });

    return true;
  }

  /**
   * Handles the cancellation of a subscription.
   *
   * @param event - The Stripe.CustomerSubscriptionDeletedEvent object representing the cancellation event.
   * @returns A boolean indicating whether the cancellation was successfully handled.
   * @throws An error if the user is not found.
   */
  public async handleSubscriptionCancelled(event: Stripe.CustomerSubscriptionDeletedEvent) {
    // get the reason for the cancellation
    const reason = event.data.object.cancellation_details?.comment;
    // set user to FREEMIUM
    const user = await this.db.account_user.findFirst({
      where: {
        stripe_customer_id: event.data.object.customer as string,
      },
    });
    if (reason === this.UPGRADE_COMMENT) {
      return false;
    }
    if (user?.stripe_subscription_id !== event.data.object.id) {
      // here the user could have multiple subs (free trial + metered),
      // we should check if the sub is the same as the user's sub
      return false;
    }
    if (!user) {
      throw new Error(`User not found -- Failed to cancel subscription for subId ${event.data.object.id}`);
    }
    if (user.organisation_id) {
      const members = await this.db.account_user.findMany({
        where: {
          organisation_id: user.organisation_id,
        },
      });
      await this.db.account_user.updateMany({
        where: {
          user_id: {
            in: members.map((m) => m.user_id),
          },
        },
        data: {
          stripe_subscription_id: null,
          subscription_plan: UserSubscription.FREEMIUM,
          stripe_metered_sub_item_id: null,
        },
      });
    }
    await this.db.account_user.update({
      where: {
        user_id: user.user_id,
      },
      data: {
        is_free_trial: false,
        subscription_plan: UserSubscription.FREEMIUM,
        stripe_metered_sub_item_id: null,
        stripe_subscription_id: null,
        credits: {
          update: {
            credits: this.determineThresholdViaSub(UserSubscription.FREEMIUM),
          },
        },
      },
    });
    // Cancel all workflows for this user.
    await this.inngest?.send({
      name: `utils/pause-all-tasks`,
      data: {
        userId: user.user_id,
      },
    });
    return true;
  }

  async setSubscriptionThreshold(subItemId: string, threshold: number) {
    return this.stripe.subscriptionItems.update(subItemId, {
      billing_thresholds: {
        usage_gte: threshold,
      },
    });
  }

  /**
   * Creates a free trial subscription for a customer.
   *
   * @param customerId - The ID of the customer.
   * @returns A promise that resolves to the created subscription.
   */
  async createFreeTrial(customerId: string) {
    try {
      return this.stripe.subscriptions.create({
        customer: customerId,
        items: [
          {
            price_data: {
              product: process.env.NEXT_PUBLIC_STRIPE_SCALE_PRODUCT_ID!,
              currency: "usd",
              recurring: {
                interval: "month",
              },
              unit_amount: 0,
            },
            quantity: 1,
          },
        ],
        trial_period_days: this.TRIAL_PERIOD_DAYS,
        payment_settings: {
          save_default_payment_method: "on_subscription",
        },
        trial_settings: {
          end_behavior: {
            missing_payment_method: "cancel",
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async handleOrgInviteCredits(event: WebhookEvent) {
    // @ts-expect-error -- cant get the correct type from clerk
    const meta = event.data?.unsafe_metadata as { organisationId?: string };
    const organisationId = meta?.organisationId!;

    if (organisationId) {
      const members = await this.clerk.organizations.getOrganizationMembershipList({
        organizationId: organisationId,
      });

      console.log("Found members", {
        members: members.data,
        total: members.totalCount,
      });

      const owner = members.data.find((member) => member.role === "org:admin"); // can only have one owner at the moment

      console.log("Found owner", { owner });

      if (!owner) {
        throw new Error("Owner not found");
      }

      const acc = await this.db.account_user.findUnique({
        where: {
          user_id: owner?.organization.createdBy,
        },
        include: {
          credits: true,
        },
      });
      return {
        creditId: acc?.credits?.id,
        owner: acc,
        organisationId,
      };
    }
  }

  /**
   * Handles the event when an organisation member is created.
   *
   * @param event - The webhook event.
   * @param orgInfo - Information about the organisation member.
   * @returns A boolean indicating whether the event was handled successfully.
   * @throws An error if the owner is not found or if an error occurs during the process.
   */
  private async handleOrganisationMemberCreated(
    event: WebhookEvent,
    orgInfo: {
      creditId: string | null;
      owner: Partial<
        Prisma.account_userGetPayload<{
          include: {
            credits: true;
          };
        }>
      > | null;
      organisationId: string | null;
    },
  ) {
    if (event.type === "user.created") {
      try {
        const ownerAccount = await this.db.account_user.findUnique({
          where: {
            email: orgInfo.owner?.email!,
          },
        });

        if (!ownerAccount) {
          throw new Error("Owner not found");
        }

        console.log("Owner account", { ownerAccount });

        await this.db.account_user.upsert({
          where: {
            email: event.data.email_addresses[0]?.email_address,
          },
          create: {
            credits: {
              connect: {
                id: orgInfo.creditId!,
              },
            },
            date_joined: new Date(),
            email: event.data.email_addresses[0]?.email_address ?? null,
            last_name: event.data.last_name ?? "",
            first_name: event.data.first_name ?? "",
            is_active: true,
            organisation_id: orgInfo.organisationId,
            subscription_plan: ownerAccount.subscription_plan,
            stripe_customer_id: ownerAccount.stripe_customer_id,
            stripe_subscription_id: ownerAccount.stripe_subscription_id,
            is_staff: false,
            is_superuser: false,
            is_free_trial: false,
            password: "",
            user_id: event.data.id,
          },
          update: {
            credits: {
              connect: {
                id: orgInfo.creditId!,
              },
            },
            organisation_id: orgInfo.organisationId,
            subscription_plan: ownerAccount.subscription_plan,
          },
        });
        return true;
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    return false;
  }

  private getFreeTrialCredits() {
    return this.FREE_TRIAL_CREDITS;
  }

  private async handleUserCreated(event: WebhookEvent) {
    if (event.type === "user.created") {
      const meta = event.data?.unsafe_metadata as { referral?: string };
      const referralCode = meta?.referral!;

      this.logger?.info(`Got referral code: ${referralCode} for user ${event.data.id}`);

      const resp = await this.createCustomer(
        event.data.email_addresses[0]?.email_address!,
        `${event.data.first_name} ${event.data.last_name}`,
        referralCode,
      );

      if (!event.data.email_addresses[0]?.email_address) {
        throw new Error("Email not found");
      }

      if (!resp) {
        throw new Error("Could not create customer");
      }

      const sub = await this.createFreeTrial(resp.id);

      if (!sub) {
        throw new Error("Could not create subscription");
      }

      const subscriptionPlan = UserSubscription.SCALE; // All new users are on the scale plan for 7 days

      // create a freemiun sub on stripe

      const user = await this.db.$transaction(async (prisma) => {
        const user = await prisma.account_user.upsert({
          where: {
            ...(event.data.email_addresses[0]?.email_address
              ? { email: event.data.email_addresses[0]?.email_address }
              : { user_id: event.data.id }),
          },
          create: {
            date_joined: new Date(),
            is_free_trial: true,
            email: event.data.email_addresses[0]?.email_address ?? null,
            last_name: event.data.last_name ?? "",
            first_name: event.data.first_name ?? "",
            is_active: true,
            organisation_id: null,
            subscription_plan: subscriptionPlan,
            stripe_customer_id: resp.id,
            stripe_subscription_id: sub.id,
            is_staff: false,
            is_superuser: false,
            password: "",
            user_id: event.data.id,
          },
          include: {
            credits: true,
          },
          update: {
            is_free_trial: true,
            subscription_plan: subscriptionPlan,
            stripe_customer_id: resp.id,
            stripe_subscription_id: sub.id,
          },
        });
        if (!user) {
          throw new Error("Error creating user");
        }
        await prisma.credits.upsert({
          where: {
            id: user.credits?.id ?? "",
          },
          create: {
            credits: this.determineThresholdViaSub(UserSubscription.SCALE),
            id: shortId(),
            account_user: {
              connect: {
                user_id: user.user_id,
              },
            },
            next_reset: addOneMonthStartOfDay(),
            receipts: {
              create: {
                action: CreditAction.CREDIT,
                id: shortId(),
                credits: this.getFreeTrialCredits(),
                user_id: user.user_id,
              },
            },
          },
          update: {},
        });
        return user;
      });

      if (!user) {
        throw new Error("Error creating user");
      }

      this.posthog?.track(PostHogEvents.USER_CREATED, user.user_id, {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        subscriptionPlan: user.subscription_plan,
        freeTrial: user.is_free_trial,
      });

      // We now mark this user to enforce onboarding.
      await this.clerk.users.updateUser(user.user_id, {
        publicMetadata: {
          ...event.data.public_metadata,
          onboardingMandatory: true,
        },
      });

      // await this.sendInviteEmail(user.email!);

      return true;
    }
  }

  /**
   *
   * @description Handles the clerk user created event - BETA
   * - Creates a prisma user if one does not exist
   * - sends an invite email to the user
   *
   */
  public async handleClerkUserCreated(event: WebhookEvent) {
    if (event.type === "user.created") {
      const orgInfo = await this.handleOrgInviteCredits(event);
      console.log("Got org info", { orgInfo });
      if (orgInfo?.organisationId) {
        return this.handleOrganisationMemberCreated(event, {
          creditId: orgInfo?.creditId!,
          organisationId: orgInfo?.organisationId,
          owner: orgInfo?.owner!,
        });
      }
      return this.handleUserCreated(event);
    }
  }

  /**
   *
   * @description Handles the clerk user updated event - BETA
   * - Credits the user with the correct amount of credits
   * - Updates the user with the beta credit date
   *
   */
  public handleClerkUserUpdated(event: WebhookEvent) {
    if (event.type === "user.updated") {
      return true;
    }
    return false;
  }

  public async handleClerkUserDeleted(event: WebhookEvent) {
    if (event.type === "user.deleted") {
      const user = await this.db.account_user.delete({
        where: {
          user_id: event.data.id,
        },
      });

      const customer = await this.stripe.customers.list({
        email: user.email ?? undefined,
      });

      if (customer.data.length === 0) {
        return true;
      }

      const resp = await this.stripe.customers.del(customer?.data[0]?.id ?? "");

      if (!resp) {
        throw new Error("Could not delete customer");
      }
      return true;
    }
    return false;
  }
  public async handleClerkOrganizationDeleted(event: WebhookEvent) {
    if (event.type === "organization.deleted") {
      await this.db.account_user.updateMany({
        where: {
          organisation_id: event.data.id,
        },
        data: {
          organisation_id: null,
        },
      });
      return true;
    }
    return false;
  }
  public async handleClerkOrganizationCreated(event: WebhookEvent) {
    try {
      if (event.type === "organization.created") {
        const data = event.data;
        this.logger?.info("[handleClerkOrganizationCreated]", data);
        // // find the owner
        const owner = data.created_by;
        const user = await this.db.account_user.findUnique({
          where: {
            user_id: owner,
          },
        });
        if (!user) {
          return {
            created: false,
            message: "Owner not found",
          };
        }
        await this.db.account_user.update({
          where: {
            user_id: owner,
          },
          data: {
            organisation_id: data.id,
          },
        });

        return {
          created: true,
          message: "Organisation created",
        };
      }
      return {
        created: false,
        message: "Not an organisation created event",
      };
    } catch (error) {
      const err = error as Error;
      this.logger?.error("[handleClerkOrganizationCreated]", error);
      return {
        created: false,
        message: err.message,
      };
    }
  }

  public async handleClerkOrganizationMembershipDeleted(event: WebhookEvent) {
    if (event.type === "organizationMembership.deleted") {
      const data = event.data;
      const userId = data?.public_user_data?.user_id;
      const orgId = data?.organization?.id;
      const user = await this.db.account_user.findUnique({
        where: {
          user_id: userId,
        },
        select: {
          organisation_id: true,
          subscription_plan: true,
          stripe_subscription_id: true,
          credit_id: true,
        },
      });

      // remove users organisation_id and credits ... put back to freemium
      await this.db.account_user.update({
        where: {
          user_id: userId,
        },
        data: {
          organisation_id: user?.organisation_id === orgId ? null : user?.organisation_id,
          subscription_plan: user?.organisation_id === orgId ? UserSubscription.FREEMIUM : user?.subscription_plan,
          stripe_subscription_id: user?.organisation_id === orgId ? null : user?.stripe_subscription_id,
          credit_id: user?.organisation_id === orgId ? null : user?.credit_id,
        },
      });

      return true;
    }
    return false;
  }

  public async createCancelSubscriptionSession(userId: string, redirectUrl: string) {
    try {
      const user = await this.db.account_user.findUnique({
        where: {
          user_id: userId,
        },
      });

      if (!user) {
        throw new Error("User not found");
      }

      const link = this.stripe.billingPortal.sessions.create({
        customer: user.stripe_customer_id!,
        return_url: redirectUrl,
        flow_data: {
          after_completion: {
            type: "redirect",
            redirect: {
              return_url: redirectUrl,
            },
          },
          type: "subscription_cancel",
          subscription_cancel: {
            subscription: user.stripe_subscription_id!,
          },
        },
      });

      return link;
    } catch (error) {
      console.error(error);
      throw new Error("Error creating billing portal session");
    }
  }

  public async getEmailMeteredUsage(userId: string) {
    return this.getMeteredUsage(userId, "email");
  }

  public async getPhoneMeteredUsage(userId: string) {
    return this.getMeteredUsage(userId, "phone");
  }
}
