import type { UserSubscription } from "@prisma/client";
import type { PostHog as PostHogClient } from "posthog-js";
import type { PostHog } from "posthog-node";

export enum PostHogEvents {
  EMAIL_ENRICHMENT_REQUESTED = "Email Enrichment Requested",
  PHONE_ENRICHMENT_REQUESTED = "Phone Enrichment Requested",
  AI_SCRIPT_GENERATED = "AI Script Generated",
  CSV_EXPORT = "CSV Export",
  CREDIT_USAGE_WARNING = "70% Credit Usage Warning",
  CREDIT_USAGE_ERROR = "100% Credit Usage Error",
  USER_PROFILE_UPDATED = "User Profile Updated",
  USER_CREATED = "User Created",
  BOUGHT_SUBSCRIPTION = "Subscription Bought",
  PEOPLE_LIST_CREATED = "People List Created",
  PEOPLE_SAMPLE_GENERATED = "People Sample Generated",
  COMPANY_LIST_CREATED = "Company List Created",
  COMPANY_LIST_CREATED_CSV = "Company List Created CSV",
  COMPANY_SAMPLE_GENERATED = "Company Sample Generated",
  JOBS_SAMPLE_GENERATED = "Jobs Sample Generated",
  JOB_AUTOMATION_STARTED = "Job Automation Started",
  CORE_SIGNAL_JOB_LIST_CREATED = "Core Signal Job List Created",
  ATS_JOB_LIST_CREATED = "ATS Job List Created",
  CRM_SYNC_COMPLETE = "CRM Sync Complete",
  WATCHED_HOW_TO_USE_TRIGIFY = "Watched How to Use Trigify",
  BOOKED_ONBOARDING_CALL = "Booked Onboarding Call",
  ACCESSED_HELP_CENTER = "Accessed Help Center",
  USER_BOOKED_APPT = "User Booked Appointment",
  USER_ONBOARDED = "User Onboarded",
  CREATED_PROFILE_AND_COMPANY = "Created Profile and Company",
  LINKEDIN_SYNC = "LinkedIn Sync Created",
}

type GetEventsResponse = {
  next: "string";
  results: PostHogEvents[];
};

export class PosthogService {
  private readonly PROJECT_ID = 54077;
  constructor(private readonly client: PostHog) {
    this.client = client;
  }

  public static sendUserCreatedEvent(
    jsClient: PostHogClient,
    userId: string,
    payload: {
      email?: string;
      freeTrial?: boolean;
      subscription_plan?: UserSubscription;
      firstName?: string;
      lastName?: string;
    },
  ) {
    jsClient.capture(PostHogEvents.USER_CREATED, {
      distinctId: userId,
      email: payload.email,
      isFreeTrial: payload.freeTrial,
      subscription: payload.subscription_plan,
      name: `${payload.firstName} ${payload.lastName}`,
    });
  }

  public track<
    TEvent extends PostHogEvents,
    // TEventProps extends PostHogEventPayloads,
  >(event: TEvent, userId: string, properties?: Record<string, unknown>) {
    this.client.capture({
      distinctId: userId,
      event,
      properties,
    });
  }

  public async getUserEvents(events: PostHogEvents[], userId: string) {
    const eventsString = events.map((event) => `'${event}'`).join(",");
    const payload = {
      query: {
        kind: "HogQLQuery",
        query: `SELECT event from events where distinct_id = '${userId}' and event IN (${eventsString})`,
      },
    };
    // params.append("event", events.join(","));
    const data = await this.client.fetch(`https://app.posthog.com/api/projects/${this.PROJECT_ID}/query`, {
      headers: {
        Authorization: `Bearer ${process.env.POSTHOG_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      method: "POST",
    });
    const json = (await data.json()) as GetEventsResponse;
    console.log("JSON", json);
    return json.results;
  }
}
